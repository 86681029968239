import React from "react";
import { Link } from "react-router-dom";

class Mobility extends React.Component {
  render() {
    return (
      <section className="list-car">
        <div className="container">
          <div className="row align-items-center">
            {/* Texte à gauche */}
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 className="heading mb-5">Nous sommes actuellement présent qu'en Côte d'Ivoire </h1>
              <p className="sm-centred">
                <strong>Spécialistes de la location de véhicules à Abidjan et en Côte d'Ivoire</strong>, 
                nous proposons une flotte récente : berlines, 4x4, minibus et utilitaires. 
                Idéal pour déplacements professionnels, tourisme ou longue durée.
              </p>

              <h2 className="subheading mt-3">Pourquoi choisir notre service ?</h2>
              <ul className="list-unstyled">
                <li>• Large choix de véhicules</li>
                <li>• Livraison : aéroport, hôtel, adresse personnelle</li>
                <li>• Partenariat avec particuliers et professionnels</li>
                <li>• Réservation en ligne 24h/24</li>
              </ul>

              <p className="mt-3">
                Implantés en Côte d'Ivoire, nous développons notre réseau en Afrique de l'Ouest tout en restant 
                votre référence pour la <strong>location de voiture à Abidjan</strong>.
              </p>
              
              <p className="mt-3">
                Découvrez notre <Link to="/vehicules">flotte de véhicules</Link> disponibles à Abidjan.
              </p>
            </div>

            {/* Image à droite */}
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="owner-img text-center">
                <img
                  loading="lazy"
                  src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/country_fyx5xi.png"
                  alt="Carte montrant la présence de notre service de location de voiture en Côte d'Ivoire"
                  className="img-fluid rounded shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Données structurées pour SEO */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "CarRentalService",
            "name": "MonBolide",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Abidjan",
              "addressCountry": "CI"
            },
            "image": "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/country_fyx5xi.png",
            "url": "https://monbolide.com"
          }
          `}
        </script>
      </section>
    );
  }
}

export default Mobility;
