import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./About.css";

class About extends React.Component {
  render() {
    const pagesList = this.props.DashboardReducer.pagesList || [];
    
    // Liste des articles du blog
    const blogArticles = [
      {
        title: "Explorer la Côte d'Ivoire : Guide Touristique Complet",
        path: "/blog/guide-touristique"
      },
      {
        title: "Conduire en Toute Sérénité en Côte d'Ivoire : Conseils et Astuces",
        path: "/blog/conduite-ivory-coast"
      },
      {
        title: "Évasion de Week-end en Côte d'Ivoire : Découvrez des Destinations Inoubliables",
        path: "/blog/escapades-weekend"
      },
      {
        title: "À la Découverte du Parc National de Taï : Un Trésor de Biodiversité en Côte d'Ivoire",
        path: "/blog/parc-national-tai"
      },
      {
        title: "Exploration en Mouvement : Itinéraires Routiers en Côte d'Ivoire pour une Aventure Inoubliable",
        path: "/blog/itineraires-routiers"
      },
      {
        title: "Choisissez Votre Compagnon de Route : Comparaisons de Véhicules pour une Location Adaptée à Vos Besoins",
        path: "/blog/comparaison-vehicules"
      },
      {
        title: "Écotourisme en Côte d'Ivoire : Redécouvrez la Nature avec Notre Plateforme d'Autopartage",
        path: "/blog/ecotourisme-ivory-coast"
      }
    ];

    return (
      <section className="about-section">
        <div className="container">
  <div className="row">
    {/* Section A propos */}
    <div className="col-md-4 about-content">
      <h2 className="title">À propos de nous</h2>
      <p className="text-white">
        Monbolide est une entreprise ivoirienne qui souhaite rendre la
        mobilité plus accessible, plus facile et plus responsable grâce
        au partage. Avec Monbolide, la location d'un véhicule en Côte
        d'Ivoire n'a jamais été aussi simple.
      </p>
    </div>

    {/* Section Partenaires */}
    <div className="col-md-4 partners-content mb-4">
      <h2 className="title">Partenaires</h2>
      <div className="partners-logos">
        <img
          src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/partenaires/paydunya_iawh4y.png"
          alt="PayDunya"
          className="partner-logo"
        />
        <img
          src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/partenaires/stripe_enovaq.jpg"
          alt="Stripe"
          className="partner-logo"
        />
      </div>
    </div>

    {/* Section Blog */}
    <div className="col-md-4 blog-content">
      <h2 className="title">Derniers Articles</h2>
      <div className="blog-articles">
        {blogArticles.map((article, index) => (
          <div key={index} className="article-item">
            <Link to={article.path} className="article-link">
              {article.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>



          {/* Section Contacts */}
          <div className="contacts-section">
            <h2 className="title">Contacts</h2>
            <div className="contacts">
              <div className="contact-item text-white">
                <i className="fas fa-map-marker-alt"></i>
                <span>Bingerville Cité Palma lot 1</span>
              </div>
              <div className="contact-item text-white">
                <i className="fas fa-phone"></i>
                <span>(+225)0700780830 / (+225)2722209208</span>
              </div>
              <div className="contact-item text-white">
                <i className="fas fa-envelope"></i>
                <span>support@monbolide.com</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(About);